import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";

export const MilestoneStorySimple = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Milestone]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];
    const color4 = props?.colors?.[3] || defaultColorScheme?.[3];

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Montserrat", color: color4 }}>
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        bottom: "1265px",
                        left: "60px",
                        fontWeight: 800,
                        fontSize: "96px",
                        width: "876px",
                        wordBreak: "break-word",
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            {textData?.sub_heading ? (
                <div
                    style={{
                        position: "absolute",
                        top: "723px",
                        left: "60px",
                        fontWeight: 500,
                        fontSize: "48px",
                        width: "910px",
                        wordBreak: "break-word",
                    }}
                >
                    {textData?.sub_heading}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        top: "891px",
                        left: "693px",
                        fontWeight: 700,
                        fontSize: "64px",
                        width: "369px",
                        height: "369px",
                        padding:"27px",
                        borderRadius: "50%",
                        backgroundColor: color1,
                        wordBreak: "break-word",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        zIndex: 1,
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {textData?.additional_text ? (
                <div
                    style={{
                        position: "absolute",
                        top: "57px",
                        left: "55px",
                        fontWeight: 600,
                        fontSize: "32px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.additional_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "805.42px",
                        height: "941.9px",
                        left: "0px",
                        bottom: "0px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        clipPath:
                            "circle(528px at calc(100% - 528px) calc(0% + 528px))",
                    }}
                />
            ) : null}

            <svg
                width="1080"
                height="1920"
                viewBox="0 0 1080 1920"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    width="1080"
                    height="1920"
                    fill="url(#paint0_linear_53_3)"
                />
                <path d="M133 48H60V60H133V48Z" fill={color1} />
                <path d="M267 98H60V110H267V98Z" fill={color1} />
                <path d="M278 655H60V683H278V655Z" fill={color1} />
                <circle cx="877.5" cy="1075.5" r="184.5" fill={color1} />
                <defs>
                    <linearGradient
                        id="paint0_linear_53_3"
                        x1="540"
                        y1="0"
                        x2="540"
                        y2="1920"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.338542" stop-color={color3} />
                        <stop offset="1" stop-color={color2} />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
