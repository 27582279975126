import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";

export const IngparPostDetailed = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Ingpar]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap"
        );
    }, []);

    return (
        <div
            style={{
                fontFamily: "Josefin Sans",
                fontWeight: 600,
                color: color3,
            }}
        >
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        top: "516px",
                        padding: "0px 101px",
                        textAlign: "center",
                        width: "1080px",
                        fontSize: "48px",
                        wordBreak: "break-word",
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        top: "139px",
                        right: "43px",
                        width: "454px",
                        padding: "2px 70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "40px",
                        textTransform: "uppercase",
                        wordBreak: "break-word",
                        backgroundColor: color2,
                        borderRadius: "22px",
                        paddingTop: "14px",
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "540px",
                        height: "560.77px",
                        top: "0px",
                        left: "0px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        clipPath: "polygon(0 0, 100% 0, 100% 68%, 0% 100%)",
                    }}
                />
            ) : null}
            {textData?.details_list?.data ? (
                <div
                    style={{
                        position: "absolute",
                        top: "638px",
                        width: "1080px",
                        fontSize: "40px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.details_list?.data?.map((item) => (
                        <div>
                            <svg
                                width="32"
                                height="32"
                                style={{
                                    marginRight: "29px",
                                    marginBottom: "5px",
                                }}
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
                                    stroke={color2}
                                    stroke-width="8"
                                />
                            </svg>
                            {item}
                        </div>
                    ))}
                </div>
            ) : null}

            <svg
                width="1080"
                height="1080"
                viewBox="0 0 1080 1080"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_689_2018)">
                    <rect width="1080" height="1080" fill={color1} />
                    <path
                        d="M363.895 768.888C365.144 771.051 366.36 773.236 367.543 775.441C365.395 774.035 363.959 771.631 363.895 768.888Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M319.798 713.287C323.551 716.809 327.191 720.449 330.713 724.202C329.351 726.719 326.688 728.429 323.625 728.429C319.177 728.429 315.571 724.823 315.571 720.375C315.571 717.312 317.281 714.649 319.798 713.287Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M268.559 676.457C270.764 677.64 272.949 678.856 275.112 680.105C272.369 680.041 269.965 678.605 268.559 676.457Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M33.888 680.105C36.0513 678.856 38.2357 677.64 40.4407 676.457C39.0353 678.605 36.6313 680.041 33.888 680.105Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-21.7129 724.202C-18.1911 720.449 -14.5508 716.809 -10.7979 713.287C-8.281 714.649 -6.57143 717.312 -6.57143 720.375C-6.57143 724.823 -10.1771 728.429 -14.625 728.429C-17.6877 728.429 -20.351 726.719 -21.7129 724.202Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-58.543 775.441C-57.36 773.236 -56.1439 771.051 -54.8951 768.888C-54.9591 771.631 -56.3949 774.035 -58.543 775.441Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M367.543 1003.56C366.36 1005.76 365.144 1007.95 363.895 1010.11C363.959 1007.37 365.395 1004.96 367.543 1003.56Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M330.713 1054.8C329.351 1052.28 326.688 1050.57 323.625 1050.57C319.177 1050.57 315.571 1054.18 315.571 1058.62C315.571 1061.69 317.281 1064.35 319.798 1065.71C323.551 1062.19 327.191 1058.55 330.713 1054.8Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M275.112 1098.9C272.369 1098.96 269.965 1100.39 268.559 1102.54C270.764 1101.36 272.949 1100.14 275.112 1098.9Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M40.4407 1102.54C39.0353 1100.39 36.6313 1098.96 33.888 1098.9C36.0513 1100.14 38.2357 1101.36 40.4407 1102.54Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-10.7979 1065.71C-8.281 1064.35 -6.57143 1061.69 -6.57143 1058.62C-6.57143 1054.18 -10.1771 1050.57 -14.625 1050.57C-17.6877 1050.57 -20.351 1052.28 -21.7129 1054.8C-18.1911 1058.55 -14.5508 1062.19 -10.7979 1065.71Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-54.8951 1010.11C-54.9591 1007.37 -56.3949 1004.96 -58.543 1003.56C-57.36 1005.76 -56.1438 1007.95 -54.8951 1010.11Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M90.0714 672.054C90.0714 676.501 86.4657 680.107 82.0179 680.107C77.57 680.107 73.9643 676.501 73.9643 672.054C73.9643 667.606 77.57 664 82.0179 664C86.4657 664 90.0714 667.606 90.0714 672.054Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M138.393 672.054C138.393 676.501 134.787 680.107 130.339 680.107C125.891 680.107 122.286 676.501 122.286 672.054C122.286 667.606 125.891 664 130.339 664C134.787 664 138.393 667.606 138.393 672.054Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M186.714 672.054C186.714 676.501 183.109 680.107 178.661 680.107C174.213 680.107 170.607 676.501 170.607 672.054C170.607 667.606 174.213 664 178.661 664C183.109 664 186.714 667.606 186.714 672.054Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M235.036 672.054C235.036 676.501 231.43 680.107 226.982 680.107C222.534 680.107 218.929 676.501 218.929 672.054C218.929 667.606 222.534 664 226.982 664C231.43 664 235.036 667.606 235.036 672.054Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M41.75 720.375C41.75 724.823 38.1443 728.429 33.6964 728.429C29.2486 728.429 25.6429 724.823 25.6429 720.375C25.6429 715.927 29.2486 712.321 33.6964 712.321C38.1443 712.321 41.75 715.927 41.75 720.375Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M90.0714 720.375C90.0714 724.823 86.4657 728.429 82.0179 728.429C77.57 728.429 73.9643 724.823 73.9643 720.375C73.9643 715.927 77.57 712.321 82.0179 712.321C86.4657 712.321 90.0714 715.927 90.0714 720.375Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M138.393 720.375C138.393 724.823 134.787 728.429 130.339 728.429C125.891 728.429 122.286 724.823 122.286 720.375C122.286 715.927 125.891 712.321 130.339 712.321C134.787 712.321 138.393 715.927 138.393 720.375Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M186.714 720.375C186.714 724.823 183.109 728.429 178.661 728.429C174.213 728.429 170.607 724.823 170.607 720.375C170.607 715.927 174.213 712.321 178.661 712.321C183.109 712.321 186.714 715.927 186.714 720.375Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M235.036 720.375C235.036 724.823 231.43 728.429 226.982 728.429C222.534 728.429 218.929 724.823 218.929 720.375C218.929 715.927 222.534 712.321 226.982 712.321C231.43 712.321 235.036 715.927 235.036 720.375Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M283.357 720.375C283.357 724.823 279.751 728.429 275.304 728.429C270.856 728.429 267.25 724.823 267.25 720.375C267.25 715.927 270.856 712.321 275.304 712.321C279.751 712.321 283.357 715.927 283.357 720.375Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-6.57143 768.696C-6.57143 773.144 -10.1771 776.75 -14.625 776.75C-19.0729 776.75 -22.6786 773.144 -22.6786 768.696C-22.6786 764.249 -19.0729 760.643 -14.625 760.643C-10.1771 760.643 -6.57143 764.249 -6.57143 768.696Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M41.75 768.696C41.75 773.144 38.1443 776.75 33.6964 776.75C29.2486 776.75 25.6429 773.144 25.6429 768.696C25.6429 764.249 29.2486 760.643 33.6964 760.643C38.1443 760.643 41.75 764.249 41.75 768.696Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M90.0714 768.696C90.0714 773.144 86.4657 776.75 82.0179 776.75C77.57 776.75 73.9643 773.144 73.9643 768.696C73.9643 764.249 77.57 760.643 82.0179 760.643C86.4657 760.643 90.0714 764.249 90.0714 768.696Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M138.393 768.696C138.393 773.144 134.787 776.75 130.339 776.75C125.891 776.75 122.286 773.144 122.286 768.696C122.286 764.249 125.891 760.643 130.339 760.643C134.787 760.643 138.393 764.249 138.393 768.696Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M186.714 768.696C186.714 773.144 183.109 776.75 178.661 776.75C174.213 776.75 170.607 773.144 170.607 768.696C170.607 764.249 174.213 760.643 178.661 760.643C183.109 760.643 186.714 764.249 186.714 768.696Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M235.036 768.696C235.036 773.144 231.43 776.75 226.982 776.75C222.534 776.75 218.929 773.144 218.929 768.696C218.929 764.249 222.534 760.643 226.982 760.643C231.43 760.643 235.036 764.249 235.036 768.696Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M283.357 768.696C283.357 773.144 279.751 776.75 275.304 776.75C270.856 776.75 267.25 773.144 267.25 768.696C267.25 764.249 270.856 760.643 275.304 760.643C279.751 760.643 283.357 764.249 283.357 768.696Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M331.679 768.696C331.679 773.144 328.073 776.75 323.625 776.75C319.177 776.75 315.571 773.144 315.571 768.696C315.571 764.249 319.177 760.643 323.625 760.643C328.073 760.643 331.679 764.249 331.679 768.696Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-54.8929 817.018C-54.8929 821.466 -58.4986 825.071 -62.9464 825.071C-67.3943 825.071 -71 821.466 -71 817.018C-71 812.57 -67.3943 808.964 -62.9464 808.964C-58.4986 808.964 -54.8929 812.57 -54.8929 817.018Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-6.57143 817.018C-6.57143 821.466 -10.1771 825.071 -14.625 825.071C-19.0729 825.071 -22.6786 821.466 -22.6786 817.018C-22.6786 812.57 -19.0729 808.964 -14.625 808.964C-10.1771 808.964 -6.57143 812.57 -6.57143 817.018Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M41.75 817.018C41.75 821.466 38.1443 825.071 33.6964 825.071C29.2486 825.071 25.6429 821.466 25.6429 817.018C25.6429 812.57 29.2486 808.964 33.6964 808.964C38.1443 808.964 41.75 812.57 41.75 817.018Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M90.0714 817.018C90.0714 821.466 86.4657 825.071 82.0179 825.071C77.57 825.071 73.9643 821.466 73.9643 817.018C73.9643 812.57 77.57 808.964 82.0179 808.964C86.4657 808.964 90.0714 812.57 90.0714 817.018Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M138.393 817.018C138.393 821.466 134.787 825.071 130.339 825.071C125.891 825.071 122.286 821.466 122.286 817.018C122.286 812.57 125.891 808.964 130.339 808.964C134.787 808.964 138.393 812.57 138.393 817.018Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M186.714 817.018C186.714 821.466 183.109 825.071 178.661 825.071C174.213 825.071 170.607 821.466 170.607 817.018C170.607 812.57 174.213 808.964 178.661 808.964C183.109 808.964 186.714 812.57 186.714 817.018Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M235.036 817.018C235.036 821.466 231.43 825.071 226.982 825.071C222.534 825.071 218.929 821.466 218.929 817.018C218.929 812.57 222.534 808.964 226.982 808.964C231.43 808.964 235.036 812.57 235.036 817.018Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M283.357 817.018C283.357 821.466 279.751 825.071 275.304 825.071C270.856 825.071 267.25 821.466 267.25 817.018C267.25 812.57 270.856 808.964 275.304 808.964C279.751 808.964 283.357 812.57 283.357 817.018Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M331.679 817.018C331.679 821.466 328.073 825.071 323.625 825.071C319.177 825.071 315.571 821.466 315.571 817.018C315.571 812.57 319.177 808.964 323.625 808.964C328.073 808.964 331.679 812.57 331.679 817.018Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M380 817.018C380 821.466 376.394 825.071 371.946 825.071C367.499 825.071 363.893 821.466 363.893 817.018C363.893 812.57 367.499 808.964 371.946 808.964C376.394 808.964 380 812.57 380 817.018Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-54.8929 865.339C-54.8929 869.787 -58.4986 873.393 -62.9464 873.393C-67.3943 873.393 -71 869.787 -71 865.339C-71 860.891 -67.3943 857.286 -62.9464 857.286C-58.4986 857.286 -54.8929 860.891 -54.8929 865.339Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-6.57143 865.339C-6.57143 869.787 -10.1771 873.393 -14.625 873.393C-19.0729 873.393 -22.6786 869.787 -22.6786 865.339C-22.6786 860.891 -19.0729 857.286 -14.625 857.286C-10.1771 857.286 -6.57143 860.891 -6.57143 865.339Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M41.75 865.339C41.75 869.787 38.1443 873.393 33.6964 873.393C29.2486 873.393 25.6429 869.787 25.6429 865.339C25.6429 860.891 29.2486 857.286 33.6964 857.286C38.1443 857.286 41.75 860.891 41.75 865.339Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M90.0714 865.339C90.0714 869.787 86.4657 873.393 82.0179 873.393C77.57 873.393 73.9643 869.787 73.9643 865.339C73.9643 860.891 77.57 857.286 82.0179 857.286C86.4657 857.286 90.0714 860.891 90.0714 865.339Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M138.393 865.339C138.393 869.787 134.787 873.393 130.339 873.393C125.891 873.393 122.286 869.787 122.286 865.339C122.286 860.891 125.891 857.286 130.339 857.286C134.787 857.286 138.393 860.891 138.393 865.339Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M186.714 865.339C186.714 869.787 183.109 873.393 178.661 873.393C174.213 873.393 170.607 869.787 170.607 865.339C170.607 860.891 174.213 857.286 178.661 857.286C183.109 857.286 186.714 860.891 186.714 865.339Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M235.036 865.339C235.036 869.787 231.43 873.393 226.982 873.393C222.534 873.393 218.929 869.787 218.929 865.339C218.929 860.891 222.534 857.286 226.982 857.286C231.43 857.286 235.036 860.891 235.036 865.339Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M283.357 865.339C283.357 869.787 279.751 873.393 275.304 873.393C270.856 873.393 267.25 869.787 267.25 865.339C267.25 860.891 270.856 857.286 275.304 857.286C279.751 857.286 283.357 860.891 283.357 865.339Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M331.679 865.339C331.679 869.787 328.073 873.393 323.625 873.393C319.177 873.393 315.571 869.787 315.571 865.339C315.571 860.891 319.177 857.286 323.625 857.286C328.073 857.286 331.679 860.891 331.679 865.339Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M380 865.339C380 869.787 376.394 873.393 371.946 873.393C367.499 873.393 363.893 869.787 363.893 865.339C363.893 860.891 367.499 857.286 371.946 857.286C376.394 857.286 380 860.891 380 865.339Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-54.8929 913.661C-54.8929 918.109 -58.4986 921.714 -62.9464 921.714C-67.3943 921.714 -71 918.109 -71 913.661C-71 909.213 -67.3943 905.607 -62.9464 905.607C-58.4986 905.607 -54.8929 909.213 -54.8929 913.661Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-6.57143 913.661C-6.57143 918.109 -10.1771 921.714 -14.625 921.714C-19.0729 921.714 -22.6786 918.109 -22.6786 913.661C-22.6786 909.213 -19.0729 905.607 -14.625 905.607C-10.1771 905.607 -6.57143 909.213 -6.57143 913.661Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M41.75 913.661C41.75 918.109 38.1443 921.714 33.6964 921.714C29.2486 921.714 25.6429 918.109 25.6429 913.661C25.6429 909.213 29.2486 905.607 33.6964 905.607C38.1443 905.607 41.75 909.213 41.75 913.661Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M90.0714 913.661C90.0714 918.109 86.4657 921.714 82.0179 921.714C77.57 921.714 73.9643 918.109 73.9643 913.661C73.9643 909.213 77.57 905.607 82.0179 905.607C86.4657 905.607 90.0714 909.213 90.0714 913.661Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M138.393 913.661C138.393 918.109 134.787 921.714 130.339 921.714C125.891 921.714 122.286 918.109 122.286 913.661C122.286 909.213 125.891 905.607 130.339 905.607C134.787 905.607 138.393 909.213 138.393 913.661Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M186.714 913.661C186.714 918.109 183.109 921.714 178.661 921.714C174.213 921.714 170.607 918.109 170.607 913.661C170.607 909.213 174.213 905.607 178.661 905.607C183.109 905.607 186.714 909.213 186.714 913.661Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M235.036 913.661C235.036 918.109 231.43 921.714 226.982 921.714C222.534 921.714 218.929 918.109 218.929 913.661C218.929 909.213 222.534 905.607 226.982 905.607C231.43 905.607 235.036 909.213 235.036 913.661Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M283.357 913.661C283.357 918.109 279.751 921.714 275.304 921.714C270.856 921.714 267.25 918.109 267.25 913.661C267.25 909.213 270.856 905.607 275.304 905.607C279.751 905.607 283.357 909.213 283.357 913.661Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M331.679 913.661C331.679 918.109 328.073 921.714 323.625 921.714C319.177 921.714 315.571 918.109 315.571 913.661C315.571 909.213 319.177 905.607 323.625 905.607C328.073 905.607 331.679 909.213 331.679 913.661Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M380 913.661C380 918.109 376.394 921.714 371.946 921.714C367.499 921.714 363.893 918.109 363.893 913.661C363.893 909.213 367.499 905.607 371.946 905.607C376.394 905.607 380 909.213 380 913.661Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-54.8929 961.982C-54.8929 966.43 -58.4986 970.036 -62.9464 970.036C-67.3943 970.036 -71 966.43 -71 961.982C-71 957.534 -67.3943 953.929 -62.9464 953.929C-58.4986 953.929 -54.8929 957.534 -54.8929 961.982Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-6.57143 961.982C-6.57143 966.43 -10.1771 970.036 -14.625 970.036C-19.0729 970.036 -22.6786 966.43 -22.6786 961.982C-22.6786 957.534 -19.0729 953.929 -14.625 953.929C-10.1771 953.929 -6.57143 957.534 -6.57143 961.982Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M41.75 961.982C41.75 966.43 38.1443 970.036 33.6964 970.036C29.2486 970.036 25.6429 966.43 25.6429 961.982C25.6429 957.534 29.2486 953.929 33.6964 953.929C38.1443 953.929 41.75 957.534 41.75 961.982Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M90.0714 961.982C90.0714 966.43 86.4657 970.036 82.0179 970.036C77.57 970.036 73.9643 966.43 73.9643 961.982C73.9643 957.534 77.57 953.929 82.0179 953.929C86.4657 953.929 90.0714 957.534 90.0714 961.982Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M138.393 961.982C138.393 966.43 134.787 970.036 130.339 970.036C125.891 970.036 122.286 966.43 122.286 961.982C122.286 957.534 125.891 953.929 130.339 953.929C134.787 953.929 138.393 957.534 138.393 961.982Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M186.714 961.982C186.714 966.43 183.109 970.036 178.661 970.036C174.213 970.036 170.607 966.43 170.607 961.982C170.607 957.534 174.213 953.929 178.661 953.929C183.109 953.929 186.714 957.534 186.714 961.982Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M235.036 961.982C235.036 966.43 231.43 970.036 226.982 970.036C222.534 970.036 218.929 966.43 218.929 961.982C218.929 957.534 222.534 953.929 226.982 953.929C231.43 953.929 235.036 957.534 235.036 961.982Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M283.357 961.982C283.357 966.43 279.751 970.036 275.304 970.036C270.856 970.036 267.25 966.43 267.25 961.982C267.25 957.534 270.856 953.929 275.304 953.929C279.751 953.929 283.357 957.534 283.357 961.982Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M331.679 961.982C331.679 966.43 328.073 970.036 323.625 970.036C319.177 970.036 315.571 966.43 315.571 961.982C315.571 957.534 319.177 953.929 323.625 953.929C328.073 953.929 331.679 957.534 331.679 961.982Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M380 961.982C380 966.43 376.394 970.036 371.946 970.036C367.499 970.036 363.893 966.43 363.893 961.982C363.893 957.534 367.499 953.929 371.946 953.929C376.394 953.929 380 957.534 380 961.982Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M-6.57143 1010.3C-6.57143 1014.75 -10.1771 1018.36 -14.625 1018.36C-19.0729 1018.36 -22.6786 1014.75 -22.6786 1010.3C-22.6786 1005.86 -19.0729 1002.25 -14.625 1002.25C-10.1771 1002.25 -6.57143 1005.86 -6.57143 1010.3Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M41.75 1010.3C41.75 1014.75 38.1443 1018.36 33.6964 1018.36C29.2486 1018.36 25.6429 1014.75 25.6429 1010.3C25.6429 1005.86 29.2486 1002.25 33.6964 1002.25C38.1443 1002.25 41.75 1005.86 41.75 1010.3Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M90.0714 1010.3C90.0714 1014.75 86.4657 1018.36 82.0179 1018.36C77.57 1018.36 73.9643 1014.75 73.9643 1010.3C73.9643 1005.86 77.57 1002.25 82.0179 1002.25C86.4657 1002.25 90.0714 1005.86 90.0714 1010.3Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M138.393 1010.3C138.393 1014.75 134.787 1018.36 130.339 1018.36C125.891 1018.36 122.286 1014.75 122.286 1010.3C122.286 1005.86 125.891 1002.25 130.339 1002.25C134.787 1002.25 138.393 1005.86 138.393 1010.3Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M186.714 1010.3C186.714 1014.75 183.109 1018.36 178.661 1018.36C174.213 1018.36 170.607 1014.75 170.607 1010.3C170.607 1005.86 174.213 1002.25 178.661 1002.25C183.109 1002.25 186.714 1005.86 186.714 1010.3Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M235.036 1010.3C235.036 1014.75 231.43 1018.36 226.982 1018.36C222.534 1018.36 218.929 1014.75 218.929 1010.3C218.929 1005.86 222.534 1002.25 226.982 1002.25C231.43 1002.25 235.036 1005.86 235.036 1010.3Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M283.357 1010.3C283.357 1014.75 279.751 1018.36 275.304 1018.36C270.856 1018.36 267.25 1014.75 267.25 1010.3C267.25 1005.86 270.856 1002.25 275.304 1002.25C279.751 1002.25 283.357 1005.86 283.357 1010.3Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M331.679 1010.3C331.679 1014.75 328.073 1018.36 323.625 1018.36C319.177 1018.36 315.571 1014.75 315.571 1010.3C315.571 1005.86 319.177 1002.25 323.625 1002.25C328.073 1002.25 331.679 1005.86 331.679 1010.3Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M41.75 1058.62C41.75 1063.07 38.1443 1066.68 33.6964 1066.68C29.2486 1066.68 25.6429 1063.07 25.6429 1058.62C25.6429 1054.18 29.2486 1050.57 33.6964 1050.57C38.1443 1050.57 41.75 1054.18 41.75 1058.62Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M90.0714 1058.62C90.0714 1063.07 86.4657 1066.68 82.0179 1066.68C77.57 1066.68 73.9643 1063.07 73.9643 1058.62C73.9643 1054.18 77.57 1050.57 82.0179 1050.57C86.4657 1050.57 90.0714 1054.18 90.0714 1058.62Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M138.393 1058.62C138.393 1063.07 134.787 1066.68 130.339 1066.68C125.891 1066.68 122.286 1063.07 122.286 1058.62C122.286 1054.18 125.891 1050.57 130.339 1050.57C134.787 1050.57 138.393 1054.18 138.393 1058.62Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M186.714 1058.62C186.714 1063.07 183.109 1066.68 178.661 1066.68C174.213 1066.68 170.607 1063.07 170.607 1058.62C170.607 1054.18 174.213 1050.57 178.661 1050.57C183.109 1050.57 186.714 1054.18 186.714 1058.62Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M235.036 1058.62C235.036 1063.07 231.43 1066.68 226.982 1066.68C222.534 1066.68 218.929 1063.07 218.929 1058.62C218.929 1054.18 222.534 1050.57 226.982 1050.57C231.43 1050.57 235.036 1054.18 235.036 1058.62Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M283.357 1058.62C283.357 1063.07 279.751 1066.68 275.304 1066.68C270.856 1066.68 267.25 1063.07 267.25 1058.62C267.25 1054.18 270.856 1050.57 275.304 1050.57C279.751 1050.57 283.357 1054.18 283.357 1058.62Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M90.0714 1106.95C90.0714 1111.39 86.4657 1115 82.0179 1115C77.57 1115 73.9643 1111.39 73.9643 1106.95C73.9643 1102.5 77.57 1098.89 82.0179 1098.89C86.4657 1098.89 90.0714 1102.5 90.0714 1106.95Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M138.393 1106.95C138.393 1111.39 134.787 1115 130.339 1115C125.891 1115 122.286 1111.39 122.286 1106.95C122.286 1102.5 125.891 1098.89 130.339 1098.89C134.787 1098.89 138.393 1102.5 138.393 1106.95Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M186.714 1106.95C186.714 1111.39 183.109 1115 178.661 1115C174.213 1115 170.607 1111.39 170.607 1106.95C170.607 1102.5 174.213 1098.89 178.661 1098.89C183.109 1098.89 186.714 1102.5 186.714 1106.95Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M235.036 1106.95C235.036 1111.39 231.43 1115 226.982 1115C222.534 1115 218.929 1111.39 218.929 1106.95C218.929 1102.5 222.534 1098.89 226.982 1098.89C231.43 1098.89 235.036 1102.5 235.036 1106.95Z"
                        fill={color2}
                        fill-opacity="0.26"
                    />
                    <path
                        d="M1080 965C1009.86 965 953 908.14 953 838C953 767.86 1009.86 711 1080 711"
                        stroke={color2}
                        stroke-width="20"
                        stroke-linecap="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_689_2018">
                        <rect width="1080" height="1080" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
