import React from "react";
import {
  loadFont,
  marketingCreativeColorSchemes,
  marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";

export const IngparStoryDetailed = (props) => {
  const { textData, coverImage } = props;

  const defaultColorScheme =
    marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Ingpar]
      ?.schemes?.[0];

  const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
  const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
  const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

  React.useEffect(() => {
    loadFont(
      "https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap"
    );
  }, []);

  return (
    <div
      style={{
        fontFamily: "Josefin Sans",
        fontWeight: 600,
        color: color3,
      }}
    >
      {textData?.heading ? (
        <div
          style={{
            position: "absolute",
            top: "1080px",
            padding: "0px 101px",
            textAlign: "center",
            width: "1080px",
            fontSize: "85px",
            wordBreak: "break-word",
          }}
        >
          {textData?.heading}
        </div>
      ) : null}
      {textData?.action_text ? (
        <div
          style={{
            position: "absolute",
            top: "1754px",
            display: "flex",
            justifyContent: "center",
            fontSize: "40px",
            width: "1080px",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
          }}
        >
          <div
            style={{
              backgroundColor: color2,
              padding: "2px 70px",
              borderRadius: "22px",
              paddingTop: "14px",
            }}
          >
            {textData?.action_text}
          </div>
        </div>
      ) : null}
      {coverImage ? (
        <div
          style={{
            position: "absolute",
            width: "1080px",
            height: "1040px",
            top: "0px",
            left: "0px",
            backgroundImage: "url(" + coverImage + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            clipPath: "polygon(0 0, 100% 0, 100% 68%, 0% 100%)",
          }}
        />
      ) : null}
      {textData?.details_list?.data ? (
        <div
          style={{
            position: "absolute",
            top: "1359px",
            width: "1080px",
            fontSize: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          {textData?.details_list?.data?.map((item) => (
            <div key={item}>
              <svg
                width="32"
                height="32"
                style={{
                  marginRight: "29px",
                  marginBottom: "5px",
                }}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
                  stroke={color2}
                  strokeWidth="8"
                />
              </svg>
              {item}
            </div>
          ))}
        </div>
      ) : null}

      <svg
        width="1080"
        height="1920"
        viewBox="0 0 1080 1920"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_689_1959)">
          <rect width="1080" height="1920" fill={color1} />
          <line
            x1="229"
            y1="1038.5"
            x2="852"
            y2="1038.5"
            stroke={color3}
            strokeWidth="3"
          />
          <path
            d="M1080 1817C1009.86 1817 953 1760.14 953 1690C953 1619.86 1009.86 1563 1080 1563"
            stroke={color2}
            strokeWidth="20"
            strokeLinecap="round"
          />
          <path
            d="M363.895 1620.89C365.144 1623.05 366.36 1625.24 367.543 1627.44C365.395 1626.04 363.959 1623.63 363.895 1620.89Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M319.798 1565.29C323.551 1568.81 327.191 1572.45 330.713 1576.2C329.351 1578.72 326.688 1580.43 323.625 1580.43C319.177 1580.43 315.571 1576.82 315.571 1572.38C315.571 1569.31 317.281 1566.65 319.798 1565.29Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M268.559 1528.46C270.764 1529.64 272.949 1530.86 275.112 1532.1C272.369 1532.04 269.965 1530.61 268.559 1528.46Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M33.888 1532.1C36.0513 1530.86 38.2357 1529.64 40.4407 1528.46C39.0353 1530.61 36.6313 1532.04 33.888 1532.1Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-21.7129 1576.2C-18.1911 1572.45 -14.5508 1568.81 -10.7979 1565.29C-8.281 1566.65 -6.57143 1569.31 -6.57143 1572.38C-6.57143 1576.82 -10.1771 1580.43 -14.625 1580.43C-17.6877 1580.43 -20.351 1578.72 -21.7129 1576.2Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-58.543 1627.44C-57.36 1625.24 -56.1439 1623.05 -54.8951 1620.89C-54.9591 1623.63 -56.3949 1626.04 -58.543 1627.44Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M367.543 1855.56C366.36 1857.76 365.144 1859.95 363.895 1862.11C363.959 1859.37 365.395 1856.96 367.543 1855.56Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M330.713 1906.8C329.351 1904.28 326.688 1902.57 323.625 1902.57C319.177 1902.57 315.571 1906.18 315.571 1910.62C315.571 1913.69 317.281 1916.35 319.798 1917.71C323.551 1914.19 327.191 1910.55 330.713 1906.8Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M275.112 1950.9C272.369 1950.96 269.965 1952.39 268.559 1954.54C270.764 1953.36 272.949 1952.14 275.112 1950.9Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M40.4407 1954.54C39.0353 1952.39 36.6313 1950.96 33.888 1950.9C36.0513 1952.14 38.2357 1953.36 40.4407 1954.54Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-10.7979 1917.71C-8.281 1916.35 -6.57143 1913.69 -6.57143 1910.62C-6.57143 1906.18 -10.1771 1902.57 -14.625 1902.57C-17.6877 1902.57 -20.351 1904.28 -21.7129 1906.8C-18.1911 1910.55 -14.5508 1914.19 -10.7979 1917.71Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-54.8951 1862.11C-54.9591 1859.37 -56.3949 1856.96 -58.543 1855.56C-57.36 1857.76 -56.1438 1859.95 -54.8951 1862.11Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M90.0714 1524.05C90.0714 1528.5 86.4657 1532.11 82.0179 1532.11C77.57 1532.11 73.9643 1528.5 73.9643 1524.05C73.9643 1519.61 77.57 1516 82.0179 1516C86.4657 1516 90.0714 1519.61 90.0714 1524.05Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M138.393 1524.05C138.393 1528.5 134.787 1532.11 130.339 1532.11C125.891 1532.11 122.286 1528.5 122.286 1524.05C122.286 1519.61 125.891 1516 130.339 1516C134.787 1516 138.393 1519.61 138.393 1524.05Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M186.714 1524.05C186.714 1528.5 183.109 1532.11 178.661 1532.11C174.213 1532.11 170.607 1528.5 170.607 1524.05C170.607 1519.61 174.213 1516 178.661 1516C183.109 1516 186.714 1519.61 186.714 1524.05Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M235.036 1524.05C235.036 1528.5 231.43 1532.11 226.982 1532.11C222.534 1532.11 218.929 1528.5 218.929 1524.05C218.929 1519.61 222.534 1516 226.982 1516C231.43 1516 235.036 1519.61 235.036 1524.05Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M41.75 1572.38C41.75 1576.82 38.1443 1580.43 33.6964 1580.43C29.2486 1580.43 25.6429 1576.82 25.6429 1572.38C25.6429 1567.93 29.2486 1564.32 33.6964 1564.32C38.1443 1564.32 41.75 1567.93 41.75 1572.38Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M90.0714 1572.38C90.0714 1576.82 86.4657 1580.43 82.0179 1580.43C77.57 1580.43 73.9643 1576.82 73.9643 1572.38C73.9643 1567.93 77.57 1564.32 82.0179 1564.32C86.4657 1564.32 90.0714 1567.93 90.0714 1572.38Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M138.393 1572.38C138.393 1576.82 134.787 1580.43 130.339 1580.43C125.891 1580.43 122.286 1576.82 122.286 1572.38C122.286 1567.93 125.891 1564.32 130.339 1564.32C134.787 1564.32 138.393 1567.93 138.393 1572.38Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M186.714 1572.38C186.714 1576.82 183.109 1580.43 178.661 1580.43C174.213 1580.43 170.607 1576.82 170.607 1572.38C170.607 1567.93 174.213 1564.32 178.661 1564.32C183.109 1564.32 186.714 1567.93 186.714 1572.38Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M235.036 1572.38C235.036 1576.82 231.43 1580.43 226.982 1580.43C222.534 1580.43 218.929 1576.82 218.929 1572.38C218.929 1567.93 222.534 1564.32 226.982 1564.32C231.43 1564.32 235.036 1567.93 235.036 1572.38Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M283.357 1572.38C283.357 1576.82 279.751 1580.43 275.304 1580.43C270.856 1580.43 267.25 1576.82 267.25 1572.38C267.25 1567.93 270.856 1564.32 275.304 1564.32C279.751 1564.32 283.357 1567.93 283.357 1572.38Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-6.57143 1620.7C-6.57143 1625.14 -10.1771 1628.75 -14.625 1628.75C-19.0729 1628.75 -22.6786 1625.14 -22.6786 1620.7C-22.6786 1616.25 -19.0729 1612.64 -14.625 1612.64C-10.1771 1612.64 -6.57143 1616.25 -6.57143 1620.7Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M41.75 1620.7C41.75 1625.14 38.1443 1628.75 33.6964 1628.75C29.2486 1628.75 25.6429 1625.14 25.6429 1620.7C25.6429 1616.25 29.2486 1612.64 33.6964 1612.64C38.1443 1612.64 41.75 1616.25 41.75 1620.7Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M90.0714 1620.7C90.0714 1625.14 86.4657 1628.75 82.0179 1628.75C77.57 1628.75 73.9643 1625.14 73.9643 1620.7C73.9643 1616.25 77.57 1612.64 82.0179 1612.64C86.4657 1612.64 90.0714 1616.25 90.0714 1620.7Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M138.393 1620.7C138.393 1625.14 134.787 1628.75 130.339 1628.75C125.891 1628.75 122.286 1625.14 122.286 1620.7C122.286 1616.25 125.891 1612.64 130.339 1612.64C134.787 1612.64 138.393 1616.25 138.393 1620.7Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M186.714 1620.7C186.714 1625.14 183.109 1628.75 178.661 1628.75C174.213 1628.75 170.607 1625.14 170.607 1620.7C170.607 1616.25 174.213 1612.64 178.661 1612.64C183.109 1612.64 186.714 1616.25 186.714 1620.7Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M235.036 1620.7C235.036 1625.14 231.43 1628.75 226.982 1628.75C222.534 1628.75 218.929 1625.14 218.929 1620.7C218.929 1616.25 222.534 1612.64 226.982 1612.64C231.43 1612.64 235.036 1616.25 235.036 1620.7Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M283.357 1620.7C283.357 1625.14 279.751 1628.75 275.304 1628.75C270.856 1628.75 267.25 1625.14 267.25 1620.7C267.25 1616.25 270.856 1612.64 275.304 1612.64C279.751 1612.64 283.357 1616.25 283.357 1620.7Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M331.679 1620.7C331.679 1625.14 328.073 1628.75 323.625 1628.75C319.177 1628.75 315.571 1625.14 315.571 1620.7C315.571 1616.25 319.177 1612.64 323.625 1612.64C328.073 1612.64 331.679 1616.25 331.679 1620.7Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-54.8929 1669.02C-54.8929 1673.47 -58.4986 1677.07 -62.9464 1677.07C-67.3943 1677.07 -71 1673.47 -71 1669.02C-71 1664.57 -67.3943 1660.96 -62.9464 1660.96C-58.4986 1660.96 -54.8929 1664.57 -54.8929 1669.02Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-6.57143 1669.02C-6.57143 1673.47 -10.1771 1677.07 -14.625 1677.07C-19.0729 1677.07 -22.6786 1673.47 -22.6786 1669.02C-22.6786 1664.57 -19.0729 1660.96 -14.625 1660.96C-10.1771 1660.96 -6.57143 1664.57 -6.57143 1669.02Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M41.75 1669.02C41.75 1673.47 38.1443 1677.07 33.6964 1677.07C29.2486 1677.07 25.6429 1673.47 25.6429 1669.02C25.6429 1664.57 29.2486 1660.96 33.6964 1660.96C38.1443 1660.96 41.75 1664.57 41.75 1669.02Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M90.0714 1669.02C90.0714 1673.47 86.4657 1677.07 82.0179 1677.07C77.57 1677.07 73.9643 1673.47 73.9643 1669.02C73.9643 1664.57 77.57 1660.96 82.0179 1660.96C86.4657 1660.96 90.0714 1664.57 90.0714 1669.02Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M138.393 1669.02C138.393 1673.47 134.787 1677.07 130.339 1677.07C125.891 1677.07 122.286 1673.47 122.286 1669.02C122.286 1664.57 125.891 1660.96 130.339 1660.96C134.787 1660.96 138.393 1664.57 138.393 1669.02Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M186.714 1669.02C186.714 1673.47 183.109 1677.07 178.661 1677.07C174.213 1677.07 170.607 1673.47 170.607 1669.02C170.607 1664.57 174.213 1660.96 178.661 1660.96C183.109 1660.96 186.714 1664.57 186.714 1669.02Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M235.036 1669.02C235.036 1673.47 231.43 1677.07 226.982 1677.07C222.534 1677.07 218.929 1673.47 218.929 1669.02C218.929 1664.57 222.534 1660.96 226.982 1660.96C231.43 1660.96 235.036 1664.57 235.036 1669.02Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M283.357 1669.02C283.357 1673.47 279.751 1677.07 275.304 1677.07C270.856 1677.07 267.25 1673.47 267.25 1669.02C267.25 1664.57 270.856 1660.96 275.304 1660.96C279.751 1660.96 283.357 1664.57 283.357 1669.02Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M331.679 1669.02C331.679 1673.47 328.073 1677.07 323.625 1677.07C319.177 1677.07 315.571 1673.47 315.571 1669.02C315.571 1664.57 319.177 1660.96 323.625 1660.96C328.073 1660.96 331.679 1664.57 331.679 1669.02Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M380 1669.02C380 1673.47 376.394 1677.07 371.946 1677.07C367.499 1677.07 363.893 1673.47 363.893 1669.02C363.893 1664.57 367.499 1660.96 371.946 1660.96C376.394 1660.96 380 1664.57 380 1669.02Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-54.8929 1717.34C-54.8929 1721.79 -58.4986 1725.39 -62.9464 1725.39C-67.3943 1725.39 -71 1721.79 -71 1717.34C-71 1712.89 -67.3943 1709.29 -62.9464 1709.29C-58.4986 1709.29 -54.8929 1712.89 -54.8929 1717.34Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-6.57143 1717.34C-6.57143 1721.79 -10.1771 1725.39 -14.625 1725.39C-19.0729 1725.39 -22.6786 1721.79 -22.6786 1717.34C-22.6786 1712.89 -19.0729 1709.29 -14.625 1709.29C-10.1771 1709.29 -6.57143 1712.89 -6.57143 1717.34Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M41.75 1717.34C41.75 1721.79 38.1443 1725.39 33.6964 1725.39C29.2486 1725.39 25.6429 1721.79 25.6429 1717.34C25.6429 1712.89 29.2486 1709.29 33.6964 1709.29C38.1443 1709.29 41.75 1712.89 41.75 1717.34Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M90.0714 1717.34C90.0714 1721.79 86.4657 1725.39 82.0179 1725.39C77.57 1725.39 73.9643 1721.79 73.9643 1717.34C73.9643 1712.89 77.57 1709.29 82.0179 1709.29C86.4657 1709.29 90.0714 1712.89 90.0714 1717.34Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M138.393 1717.34C138.393 1721.79 134.787 1725.39 130.339 1725.39C125.891 1725.39 122.286 1721.79 122.286 1717.34C122.286 1712.89 125.891 1709.29 130.339 1709.29C134.787 1709.29 138.393 1712.89 138.393 1717.34Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M186.714 1717.34C186.714 1721.79 183.109 1725.39 178.661 1725.39C174.213 1725.39 170.607 1721.79 170.607 1717.34C170.607 1712.89 174.213 1709.29 178.661 1709.29C183.109 1709.29 186.714 1712.89 186.714 1717.34Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M235.036 1717.34C235.036 1721.79 231.43 1725.39 226.982 1725.39C222.534 1725.39 218.929 1721.79 218.929 1717.34C218.929 1712.89 222.534 1709.29 226.982 1709.29C231.43 1709.29 235.036 1712.89 235.036 1717.34Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M283.357 1717.34C283.357 1721.79 279.751 1725.39 275.304 1725.39C270.856 1725.39 267.25 1721.79 267.25 1717.34C267.25 1712.89 270.856 1709.29 275.304 1709.29C279.751 1709.29 283.357 1712.89 283.357 1717.34Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M331.679 1717.34C331.679 1721.79 328.073 1725.39 323.625 1725.39C319.177 1725.39 315.571 1721.79 315.571 1717.34C315.571 1712.89 319.177 1709.29 323.625 1709.29C328.073 1709.29 331.679 1712.89 331.679 1717.34Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M380 1717.34C380 1721.79 376.394 1725.39 371.946 1725.39C367.499 1725.39 363.893 1721.79 363.893 1717.34C363.893 1712.89 367.499 1709.29 371.946 1709.29C376.394 1709.29 380 1712.89 380 1717.34Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-54.8929 1765.66C-54.8929 1770.11 -58.4986 1773.71 -62.9464 1773.71C-67.3943 1773.71 -71 1770.11 -71 1765.66C-71 1761.21 -67.3943 1757.61 -62.9464 1757.61C-58.4986 1757.61 -54.8929 1761.21 -54.8929 1765.66Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-6.57143 1765.66C-6.57143 1770.11 -10.1771 1773.71 -14.625 1773.71C-19.0729 1773.71 -22.6786 1770.11 -22.6786 1765.66C-22.6786 1761.21 -19.0729 1757.61 -14.625 1757.61C-10.1771 1757.61 -6.57143 1761.21 -6.57143 1765.66Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M41.75 1765.66C41.75 1770.11 38.1443 1773.71 33.6964 1773.71C29.2486 1773.71 25.6429 1770.11 25.6429 1765.66C25.6429 1761.21 29.2486 1757.61 33.6964 1757.61C38.1443 1757.61 41.75 1761.21 41.75 1765.66Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M90.0714 1765.66C90.0714 1770.11 86.4657 1773.71 82.0179 1773.71C77.57 1773.71 73.9643 1770.11 73.9643 1765.66C73.9643 1761.21 77.57 1757.61 82.0179 1757.61C86.4657 1757.61 90.0714 1761.21 90.0714 1765.66Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M138.393 1765.66C138.393 1770.11 134.787 1773.71 130.339 1773.71C125.891 1773.71 122.286 1770.11 122.286 1765.66C122.286 1761.21 125.891 1757.61 130.339 1757.61C134.787 1757.61 138.393 1761.21 138.393 1765.66Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M186.714 1765.66C186.714 1770.11 183.109 1773.71 178.661 1773.71C174.213 1773.71 170.607 1770.11 170.607 1765.66C170.607 1761.21 174.213 1757.61 178.661 1757.61C183.109 1757.61 186.714 1761.21 186.714 1765.66Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M235.036 1765.66C235.036 1770.11 231.43 1773.71 226.982 1773.71C222.534 1773.71 218.929 1770.11 218.929 1765.66C218.929 1761.21 222.534 1757.61 226.982 1757.61C231.43 1757.61 235.036 1761.21 235.036 1765.66Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M283.357 1765.66C283.357 1770.11 279.751 1773.71 275.304 1773.71C270.856 1773.71 267.25 1770.11 267.25 1765.66C267.25 1761.21 270.856 1757.61 275.304 1757.61C279.751 1757.61 283.357 1761.21 283.357 1765.66Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M331.679 1765.66C331.679 1770.11 328.073 1773.71 323.625 1773.71C319.177 1773.71 315.571 1770.11 315.571 1765.66C315.571 1761.21 319.177 1757.61 323.625 1757.61C328.073 1757.61 331.679 1761.21 331.679 1765.66Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M380 1765.66C380 1770.11 376.394 1773.71 371.946 1773.71C367.499 1773.71 363.893 1770.11 363.893 1765.66C363.893 1761.21 367.499 1757.61 371.946 1757.61C376.394 1757.61 380 1761.21 380 1765.66Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-54.8929 1813.98C-54.8929 1818.43 -58.4986 1822.04 -62.9464 1822.04C-67.3943 1822.04 -71 1818.43 -71 1813.98C-71 1809.53 -67.3943 1805.93 -62.9464 1805.93C-58.4986 1805.93 -54.8929 1809.53 -54.8929 1813.98Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-6.57143 1813.98C-6.57143 1818.43 -10.1771 1822.04 -14.625 1822.04C-19.0729 1822.04 -22.6786 1818.43 -22.6786 1813.98C-22.6786 1809.53 -19.0729 1805.93 -14.625 1805.93C-10.1771 1805.93 -6.57143 1809.53 -6.57143 1813.98Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M41.75 1813.98C41.75 1818.43 38.1443 1822.04 33.6964 1822.04C29.2486 1822.04 25.6429 1818.43 25.6429 1813.98C25.6429 1809.53 29.2486 1805.93 33.6964 1805.93C38.1443 1805.93 41.75 1809.53 41.75 1813.98Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M90.0714 1813.98C90.0714 1818.43 86.4657 1822.04 82.0179 1822.04C77.57 1822.04 73.9643 1818.43 73.9643 1813.98C73.9643 1809.53 77.57 1805.93 82.0179 1805.93C86.4657 1805.93 90.0714 1809.53 90.0714 1813.98Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M138.393 1813.98C138.393 1818.43 134.787 1822.04 130.339 1822.04C125.891 1822.04 122.286 1818.43 122.286 1813.98C122.286 1809.53 125.891 1805.93 130.339 1805.93C134.787 1805.93 138.393 1809.53 138.393 1813.98Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M186.714 1813.98C186.714 1818.43 183.109 1822.04 178.661 1822.04C174.213 1822.04 170.607 1818.43 170.607 1813.98C170.607 1809.53 174.213 1805.93 178.661 1805.93C183.109 1805.93 186.714 1809.53 186.714 1813.98Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M235.036 1813.98C235.036 1818.43 231.43 1822.04 226.982 1822.04C222.534 1822.04 218.929 1818.43 218.929 1813.98C218.929 1809.53 222.534 1805.93 226.982 1805.93C231.43 1805.93 235.036 1809.53 235.036 1813.98Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M283.357 1813.98C283.357 1818.43 279.751 1822.04 275.304 1822.04C270.856 1822.04 267.25 1818.43 267.25 1813.98C267.25 1809.53 270.856 1805.93 275.304 1805.93C279.751 1805.93 283.357 1809.53 283.357 1813.98Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M331.679 1813.98C331.679 1818.43 328.073 1822.04 323.625 1822.04C319.177 1822.04 315.571 1818.43 315.571 1813.98C315.571 1809.53 319.177 1805.93 323.625 1805.93C328.073 1805.93 331.679 1809.53 331.679 1813.98Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M380 1813.98C380 1818.43 376.394 1822.04 371.946 1822.04C367.499 1822.04 363.893 1818.43 363.893 1813.98C363.893 1809.53 367.499 1805.93 371.946 1805.93C376.394 1805.93 380 1809.53 380 1813.98Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M-6.57143 1862.3C-6.57143 1866.75 -10.1771 1870.36 -14.625 1870.36C-19.0729 1870.36 -22.6786 1866.75 -22.6786 1862.3C-22.6786 1857.86 -19.0729 1854.25 -14.625 1854.25C-10.1771 1854.25 -6.57143 1857.86 -6.57143 1862.3Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M41.75 1862.3C41.75 1866.75 38.1443 1870.36 33.6964 1870.36C29.2486 1870.36 25.6429 1866.75 25.6429 1862.3C25.6429 1857.86 29.2486 1854.25 33.6964 1854.25C38.1443 1854.25 41.75 1857.86 41.75 1862.3Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M90.0714 1862.3C90.0714 1866.75 86.4657 1870.36 82.0179 1870.36C77.57 1870.36 73.9643 1866.75 73.9643 1862.3C73.9643 1857.86 77.57 1854.25 82.0179 1854.25C86.4657 1854.25 90.0714 1857.86 90.0714 1862.3Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M138.393 1862.3C138.393 1866.75 134.787 1870.36 130.339 1870.36C125.891 1870.36 122.286 1866.75 122.286 1862.3C122.286 1857.86 125.891 1854.25 130.339 1854.25C134.787 1854.25 138.393 1857.86 138.393 1862.3Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M186.714 1862.3C186.714 1866.75 183.109 1870.36 178.661 1870.36C174.213 1870.36 170.607 1866.75 170.607 1862.3C170.607 1857.86 174.213 1854.25 178.661 1854.25C183.109 1854.25 186.714 1857.86 186.714 1862.3Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M235.036 1862.3C235.036 1866.75 231.43 1870.36 226.982 1870.36C222.534 1870.36 218.929 1866.75 218.929 1862.3C218.929 1857.86 222.534 1854.25 226.982 1854.25C231.43 1854.25 235.036 1857.86 235.036 1862.3Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M283.357 1862.3C283.357 1866.75 279.751 1870.36 275.304 1870.36C270.856 1870.36 267.25 1866.75 267.25 1862.3C267.25 1857.86 270.856 1854.25 275.304 1854.25C279.751 1854.25 283.357 1857.86 283.357 1862.3Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M331.679 1862.3C331.679 1866.75 328.073 1870.36 323.625 1870.36C319.177 1870.36 315.571 1866.75 315.571 1862.3C315.571 1857.86 319.177 1854.25 323.625 1854.25C328.073 1854.25 331.679 1857.86 331.679 1862.3Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M41.75 1910.62C41.75 1915.07 38.1443 1918.68 33.6964 1918.68C29.2486 1918.68 25.6429 1915.07 25.6429 1910.62C25.6429 1906.18 29.2486 1902.57 33.6964 1902.57C38.1443 1902.57 41.75 1906.18 41.75 1910.62Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M90.0714 1910.62C90.0714 1915.07 86.4657 1918.68 82.0179 1918.68C77.57 1918.68 73.9643 1915.07 73.9643 1910.62C73.9643 1906.18 77.57 1902.57 82.0179 1902.57C86.4657 1902.57 90.0714 1906.18 90.0714 1910.62Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M138.393 1910.62C138.393 1915.07 134.787 1918.68 130.339 1918.68C125.891 1918.68 122.286 1915.07 122.286 1910.62C122.286 1906.18 125.891 1902.57 130.339 1902.57C134.787 1902.57 138.393 1906.18 138.393 1910.62Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M186.714 1910.62C186.714 1915.07 183.109 1918.68 178.661 1918.68C174.213 1918.68 170.607 1915.07 170.607 1910.62C170.607 1906.18 174.213 1902.57 178.661 1902.57C183.109 1902.57 186.714 1906.18 186.714 1910.62Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M235.036 1910.62C235.036 1915.07 231.43 1918.68 226.982 1918.68C222.534 1918.68 218.929 1915.07 218.929 1910.62C218.929 1906.18 222.534 1902.57 226.982 1902.57C231.43 1902.57 235.036 1906.18 235.036 1910.62Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M283.357 1910.62C283.357 1915.07 279.751 1918.68 275.304 1918.68C270.856 1918.68 267.25 1915.07 267.25 1910.62C267.25 1906.18 270.856 1902.57 275.304 1902.57C279.751 1902.57 283.357 1906.18 283.357 1910.62Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M90.0714 1958.95C90.0714 1963.39 86.4657 1967 82.0179 1967C77.57 1967 73.9643 1963.39 73.9643 1958.95C73.9643 1954.5 77.57 1950.89 82.0179 1950.89C86.4657 1950.89 90.0714 1954.5 90.0714 1958.95Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M138.393 1958.95C138.393 1963.39 134.787 1967 130.339 1967C125.891 1967 122.286 1963.39 122.286 1958.95C122.286 1954.5 125.891 1950.89 130.339 1950.89C134.787 1950.89 138.393 1954.5 138.393 1958.95Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M186.714 1958.95C186.714 1963.39 183.109 1967 178.661 1967C174.213 1967 170.607 1963.39 170.607 1958.95C170.607 1954.5 174.213 1950.89 178.661 1950.89C183.109 1950.89 186.714 1954.5 186.714 1958.95Z"
            fill={color2}
            fillOpacity="0.26"
          />
          <path
            d="M235.036 1958.95C235.036 1963.39 231.43 1967 226.982 1967C222.534 1967 218.929 1963.39 218.929 1958.95C218.929 1954.5 222.534 1950.89 226.982 1950.89C231.43 1950.89 235.036 1954.5 235.036 1958.95Z"
            fill={color2}
            fillOpacity="0.26"
          />
        </g>
        <defs>
          <clipPath id="clip0_689_1959">
            <rect width="1080" height="1920" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
